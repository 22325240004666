import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const TermsOfUse: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <a href="/"><img src={horizontalLogo} className='mx-auto' alt="Logo" style={{ height: 100 }} /></a>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                Kullanım Şartları
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    Bu Kullanım Şartları, Langugo LLC tarafından geliştirilen ve işletilen "Fallasana" markalı mobil uygulamanın kullanımı ile ilgili olarak geçerli olan koşulları ve kuralları ayrıntılı bir şekilde belirlemek amacıyla hazırlanmıştır. Fallasana, kullanıcılara çeşitli fal kategorilerinde (Kahve Falı, Tarot Falı, İskambil Falı, Yüz Falı, El Falı, Burçlar, Rüya Yorumları, Astroloji, Numeroloji, Şanslı Sayılar) yapay zeka destekli yorumlar sunar. Bu Kullanım Şartları'nı kabul ederek, Fallasana uygulamasını kullanma hakkına sahip olduğunuzu ve bu şartlara uymayı taahhüt ettiğinizi beyan etmiş olursunuz. Bu şartları kabul etmemeniz durumunda, lütfen uygulamayı kullanmayı derhal bırakınız.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Kişisel Bilgilerin Toplanması ve Kullanımı</h2>
                <p>
                    Fallasana uygulaması, kullanıcılarının kimliklerini koruma ve gizliliklerini muhafaza etme taahhüdü çerçevesinde, e-posta adresi, telefon numarası, sosyal medya hesapları veya herhangi bir diğer kişisel bilgi toplamaz. Bu sayede, kullanıcılar uygulamayı tam anlamıyla anonim olarak kullanabilir ve kimliklerini ifşa etmek zorunda kalmazlar.
                </p>
                <p>
                    Uygulamanın işlevselliğini artırmak ve kullanıcılara daha kişisel ve hedeflenmiş yorumlar sunabilmek amacıyla, kullanıcılardan isim, doğum tarihi, doğum saati, doğduğu şehir, eğitim durumu, medeni hal ve maddi durum gibi demografik bilgiler anonim olarak talep edilebilir. Bu bilgiler, kullanıcının kimliğiyle ilişkilendirilmeden sadece yorumların doğruluğunu artırmak amacıyla kullanılacaktır.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. Ücretsiz ve Premium Üyelik Seçenekleri</h2>
                <p>
                    Fallasana uygulaması, her kullanıcısına günlük olarak 1 adet ücretsiz elmas sağlar. Bu elmas, kullanıcının herhangi bir kategoride fal baktırmasına olanak tanır. Bu ücretsiz elmas hakkı, Fallasana yönetimi tarafından herhangi bir bildirimde bulunmaksızın değiştirilme, kaldırılma veya yeni kampanyalarla ikame edilme hakkı saklı tutularak sunulmaktadır.
                </p>
                <p>
                    Fallasana, kullanıcılarına haftalık, aylık ve yıllık olmak üzere farklı sürelerde Premium üyelik seçenekleri sunar. Premium üyelik, kullanıcılara uygulamanın daha geniş kapsamlı özelliklerinden yararlanma imkânı tanır ve günlük olarak 10 adet ücretsiz elmas hakkı sağlar. Ancak, bu elmaslar yalnızca ilgili gün içinde kullanılmalı ve kullanılmayan elmaslar ertesi güne devredilmeyerek sıfırlanır; dolayısıyla, birikim yapma imkânı bulunmamaktadır.
                </p>
                <p>
                    Premium üyelik kapsamında sağlanan elmaslar tükendiğinde, kullanıcılar ek elmas paketlerini tekil olarak satın alarak fal baktırmaya devam edebilirler.
                </p>
                <p>
                    Kullanıcılar, Fallasana uygulamasını arkadaşlarıyla paylaşarak ek ücretsiz elmas kazanma şansına sahip olabilirler. Bununla birlikte, kullanıcılar, uygulama içindeki reklamları izleyerek de ücretsiz elmas kazanabilirler.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Uygulamanın Amacı ve Kullanımı</h2>
                <p>
                    Fallasana uygulaması, yalnızca eğlence ve keyif amaçlı bir dijital platform olarak hizmet vermektedir. Uygulama kapsamında sunulan fal yorumları, tamamen yapay zeka algoritmaları tarafından oluşturulmakta olup, gerçek hayattaki olayları, durumları veya kişisel kararları kesinlikle yansıtmak üzere tasarlanmamıştır.
                </p>
                <p>
                    Bu sebeple, Fallasana uygulaması tarafından sunulan yorumlar veya öngörüler, profesyonel bir danışmanlık hizmeti olarak değerlendirilmemeli ve hukuki, tıbbi, finansal ya da kişisel kararlar alırken bu yorumlara güvenilmemelidir. Langugo LLC, bu yorumların herhangi bir sonuç doğurmasından sorumlu tutulamaz.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Feragatname (Sorumluluk Reddi)</h2>
                <p>
                    Fallasana uygulaması, kullanıcılarına sunmuş olduğu hizmetlerin tamamen eğlence amaçlı olduğunu açıkça beyan eder. Uygulama kapsamında yer alan fal yorumları, kullanıcıların kişisel bilgilerinden ve tercihlerinden bağımsız olarak yapay zeka tarafından üretilen metinlerden ibarettir. Bu yorumlar, herhangi bir profesyonel tavsiye, rehberlik veya garanti sunmamakta olup, kullanıcılar tarafından yalnızca eğlence ve keyif amacıyla değerlendirilmelidir.
                </p>
                <p>
                    Langugo LLC, Fallasana uygulamasında sunulan fal yorumlarının doğruluğu, geçerliliği veya güvenilirliği konusunda hiçbir sorumluluk kabul etmez. Kullanıcılar, bu yorumların yalnızca birer eğlence unsuru olduğunu kabul ederler ve yapay zeka tarafından üretilen bu yorumlara dayanarak alacakları kararlardan doğabilecek her türlü sonuçtan tamamen kendileri sorumlu olacaktır.
                </p>
                <p>
                    Fallasana uygulamasında yer alan fal yorumları, kullanıcıların geleceğine yönelik kesin tahminler veya garantiler içermez. Bu nedenle, Langugo LLC, kullanıcıların bu yorumlara dayanarak yapacakları eylemlerden veya alacakları kararlardan doğabilecek herhangi bir zarardan sorumlu tutulamaz.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Üçüncü Taraf Reklamları ve Ödülleri</h2>
                <p>
                    Fallasana uygulaması, üçüncü taraf reklamları içerebilir ve bu reklamlar, kullanıcıların ücretsiz elmas kazanma şansını artırmak amacıyla sunulabilir. Kullanıcılar, bu reklamları izleyerek ek ücretsiz elmas kazanabilirler. Ancak, üçüncü taraf reklamlarının içeriği ve bu reklamlardan doğabilecek herhangi bir sorundan dolayı Langugo LLC sorumlu tutulamaz. Reklamların içeriği üzerinde herhangi bir kontrolümüz bulunmamaktadır ve kullanıcılar bu tür reklamları kendi takdirlerine göre izlemelidir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Kullanım Şartları'nda Değişiklikler ve Güncellemeler</h2>
                <p>
                    Langugo LLC, Fallasana uygulamasının Kullanım Şartları'nı dilediği zaman değiştirme veya güncelleme hakkını saklı tutar. Yapılan değişiklikler veya güncellemeler, kullanıcıya uygulama içi bildirimler veya e-posta yoluyla duyurulacaktır. Bu değişikliklerin ardından uygulamayı kullanmaya devam etmeniz, değişen şartları kabul ettiğiniz anlamına gelecektir. Şartları kabul etmemeniz durumunda, uygulamayı kullanmayı derhal bırakmalısınız.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Uygulamanın Feshi ve Kullanıcı Hesaplarının Kapatılması</h2>
                <p>
                    Fallasana, Kullanım Şartları'nı ihlal eden veya uygulamayı kötüye kullanan kullanıcıların hesaplarını herhangi bir uyarı yapmaksızın askıya alma veya kalıcı olarak kapatma hakkını saklı tutar. Bu tür durumlarda, kullanıcıların kalan elmasları veya üyelik süreleri geri ödenmez.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Uygulama ve Yargı Yetkisi</h2>
                <p>
                    Bu Kullanım Şartları, Amerika Birleşik Devletleri, Wyoming Eyaleti yasalarına tabi olup, bu yasalara göre yorumlanacak ve uygulanacaktır. Fallasana uygulamasından doğabilecek herhangi bir ihtilaf durumunda, yalnızca Wyoming Eyaleti mahkemeleri yetkili olacaktır. Bu tür hukuki ihtilaflar, bu mahkemelerce karara bağlanacaktır.
                </p>

                <p>
                    Fallasana uygulamasını kullanarak, yukarıda belirtilen tüm şartları ve koşulları dikkatlice okuduğunuzu, anladığınızı ve kabul ettiğinizi beyan etmiş olursunuz. Bu şartların herhangi birine uymamanız durumunda, uygulamayı kullanmayı derhal bırakmanız gerekmektedir. Sorularınız veya yardıma ihtiyacınız olması durumunda, aşağıda belirtilen destek e-posta adresi üzerinden bizimle iletişime geçebilirsiniz.
                </p>
                <p>
                    Her türlü soru ve önerileriniz için e-posta adresi: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Son Güncelleme: 01.09.2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default TermsOfUse;
