import React from 'react'

interface CarouselCardProps {
    image: string;
    title: string;
    description: string;
}

const CarouselCard: React.FC<CarouselCardProps> = ({ image, title, description }) => {
    return (
        <div className='flex flex-col space-y-3 w-80 h-[380px] bg-zinc-900  rounded-lg'>
            <img src={image} alt={title} className='w-80 h-56 rounded-lg' />
            <div className='px-5 pb-5'>
                <h3 className='text-white font-semibold text-[20px]'>{title}</h3>
                <p className='text-[15px] text-gray-300 w-90'>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default CarouselCard