import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const EULA: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <a href="/"><img src={horizontalLogo} className='mx-auto' alt="Logo" style={{ height: 100 }} /></a>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                Son Kullanıcı Lisans Sözleşmesi (EULA)
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    Bu Son Kullanıcı Lisans Sözleşmesi (EULA), Langugo LLC tarafından geliştirilen ve işletilen "Fallasana" markalı mobil uygulamanın (bundan sonra "Uygulama" olarak anılacaktır) kullanımı ile ilgili şartları belirler. Uygulamayı indirerek, kurarak veya kullanarak, bu EULA'nın tüm hükümlerini kabul ettiğinizi beyan etmiş olursunuz. Bu şartları kabul etmiyorsanız, lütfen Uygulamayı kullanmayı derhal bırakın ve cihazınızdan kaldırın.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Lisansın Verilmesi</h2>
                <p>
                    Langugo LLC, bu EULA kapsamında size, Uygulamayı kişisel, ticari olmayan, sınırlı, geri alınabilir ve münhasır olmayan bir lisans altında kullanma hakkı vermektedir. Bu lisans, Uygulamayı yalnızca bu EULA'da belirtilen koşullara uygun olarak kullanmanız şartıyla geçerlidir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. Lisansın Sınırlamaları</h2>
                <p>
                    Uygulamayı kullanırken, aşağıdaki sınırlamalara uymanız gerekmektedir:
                </p>
                <ul className='list-disc list-inside'>
                    <li>Uygulamayı yeniden satamaz, kiralayamaz, ödünç veremez veya herhangi bir üçüncü tarafa devredemezsiniz.</li>
                    <li>Uygulamanın kaynak kodunu çözümleme, tersine mühendislik yapma veya türev çalışmalar oluşturma girişiminde bulunamazsınız.</li>
                    <li>Uygulamayı yasa dışı amaçlarla veya bu EULA'ya aykırı bir şekilde kullanamazsınız.</li>
                    <li>Uygulama içeriğini veya işlevselliğini değiştirmeye yönelik herhangi bir girişimde bulunamazsınız.</li>
                </ul>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Kişisel Bilgilerin Toplanmaması</h2>
                <p>
                    Uygulama, kullanıcılarından e-posta adresi, telefon numarası, sosyal medya hesap bilgileri gibi kişisel bilgileri toplamaz. Kullanıcılar, Uygulamayı tamamen anonim olarak kullanabilirler. Ancak, Uygulama içi deneyimi iyileştirmek amacıyla, kullanıcıların kimlikleriyle ilişkilendirilmeyen anonim demografik bilgiler (isim, doğum tarihi, doğum saati, doğduğu şehir, eğitim durumu, medeni hal, maddi durum) toplanabilir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Ücretsiz ve Premium Üyelik</h2>
                <p>
                    Uygulama, kullanıcılarına günlük olarak bir adet ücretsiz elmas sunar ve bu elmas ile bir fal baktırma imkânı sağlar. Bu ücretsiz elmas hakkı, Langugo LLC tarafından herhangi bir zamanda değiştirilebilir veya kaldırılabilir.
                </p>
                <p>
                    Uygulamada haftalık, aylık ve yıllık Premium üyelik paketleri sunulmaktadır. Premium üyeler, günlük olarak on adet ücretsiz elmas kazanırlar. Bu elmaslar yalnızca ilgili gün içinde kullanılabilir ve kullanılmayan elmaslar ertesi güne devredilmez.
                </p>
                <p>
                    Premium üyelik kapsamında sağlanan elmaslar tükendiğinde, kullanıcılar ek elmas paketlerini satın alabilirler.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Üçüncü Taraf İçerikleri</h2>
                <p>
                    Uygulama, üçüncü taraflarca sağlanan reklamları ve içerikleri içerebilir. Bu içeriklerin doğruluğu, geçerliliği veya yasallığı konusunda Langugo LLC herhangi bir sorumluluk kabul etmez. Üçüncü taraf reklamları izleyerek ücretsiz elmas kazanılabilir, ancak bu süreçte kullanıcıların kişisel bilgileri toplanmaz.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Güncellemeler ve Değişiklikler</h2>
                <p>
                    Langugo LLC, Uygulamanın işlevselliğini artırmak amacıyla zaman zaman güncellemeler veya değişiklikler yapabilir. Bu güncellemeler, kullanıcının cihazına otomatik olarak indirilebilir ve yüklenebilir. Güncellemeleri kabul etmemeyi tercih ederseniz, Uygulamayı kullanmayı durdurmanız gerekebilir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Fesih</h2>
                <p>
                    Bu EULA, kullanıcıların Uygulamayı kullanmaya devam ettiği sürece geçerlidir. Ancak, Langugo LLC, kullanıcıların bu EULA'ya aykırı davranmaları durumunda, herhangi bir bildirimde bulunmaksızın kullanıcıların erişimini askıya alma veya kalıcı olarak sonlandırma hakkını saklı tutar.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Sorumluluk Reddi</h2>
                <p>
                    Uygulama, tamamen eğlence amaçlı bir hizmet olarak sunulmaktadır. Uygulama kapsamında sunulan fal yorumları, yapay zeka tarafından otomatik olarak üretilen metinlerdir ve hiçbir şekilde profesyonel tavsiye olarak değerlendirilmemelidir. Langugo LLC, bu yorumların doğruluğu, güvenilirliği veya kullanıcıların bu yorumlara dayanarak alacakları kararlardan doğabilecek herhangi bir sonuçtan sorumlu tutulamaz.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>9. Sınırlı Garanti</h2>
                <p>
                    Uygulama, "olduğu gibi" ve "mevcut haliyle" sunulmaktadır. Langugo LLC, Uygulamanın kesintisiz, hatasız veya güvenli bir şekilde çalışacağını garanti etmez. Kullanıcılar, Uygulamayı kendi riskleriyle kullanır ve Uygulamanın kullanımından doğabilecek her türlü zarardan sorumludur.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>10. Uygulanabilir Hukuk ve Yargı Yetkisi</h2>
                <p>
                    Bu EULA, Amerika Birleşik Devletleri, Wyoming Eyaleti yasalarına tabi olup, bu yasalara göre yorumlanacak ve uygulanacaktır. Bu EULA'dan doğabilecek her türlü hukuki ihtilaf durumunda, yalnızca Wyoming Eyaleti mahkemeleri yetkili olacaktır.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>11. İletişim Bilgileri</h2>
                <p>
                    Bu EULA ile ilgili herhangi bir sorunuz, şikayetiniz veya geri bildiriminiz olması durumunda, aşağıda belirtilen destek e-posta adresi üzerinden Langugo LLC ile iletişime geçebilirsiniz.
                </p>
                <p>
                    Her türlü soru ve önerileriniz için e-posta adresi: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Son Güncelleme: 01.09.2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default EULA;
