import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselCard from "./CarouselCard";
import coffeeImage from "../assets/fortunes/turkish_coffe.png";
import tarotImage from "../assets/fortunes/tarot.png";
import iskambilImage from "../assets/fortunes/playing_cards.png";
import faceImage from "../assets/fortunes/fortune_teller.png";
import handImage from "../assets/fortunes/hands.png";
import numerologyImage from "../assets/fortunes/numbers.png";
import burcImage from "../assets/fortunes/zodiac.png";
import dreamImage from "../assets/fortunes/dream_comments.png";
import astrologyImage from "../assets/fortunes/astrology.png";
import luckyNumbersImage from "../assets/fortunes/daily_lucky_numbers_2.png";
const Carousel = () => {
    const settings = {
        dots: false,
        draggable: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768, // Bu değeri mobil genişlik için belirleyin (örneğin 768px ve altı için)
                settings: {
                    slidesToShow: 1, // Mobilde 1 item gösterir
                },
            },
            {
                breakpoint: 1024, // Tablet genişliği için (örneğin 1024px ve altı için)
                settings: {
                    slidesToShow: 2, // Tablet görünümde 2 item gösterir
                },
            },
        ],
    };

    return (
        <div className="px-10">
            <Slider {...settings}>
                <CarouselCard image={coffeeImage} title={"Kahve Falı"} description={"Kahve falı, içilen kahvenin telvesinde oluşan şekillerin yorumlanarak geleceğe dair tahminlerde bulunma sanatıdır."} />
                <CarouselCard image={tarotImage} title={"Tarot Falı"} description={"Tarot falı, 78 karttan oluşan bir desteyle kişinin geçmiş, şimdi ve geleceği hakkında kehanet yapar."} />
                <CarouselCard image={iskambilImage} title={"İskambil Falı"} description={"İskambil falı, klasik iskambil kartlarıyla kişinin hayatındaki gizemleri ve gelecekteki olasılıkları ortaya çıkarır"} />
                <CarouselCard image={faceImage} title={"Yüz Falı"} description={"Yüz falı, yüz hatları ve ifadeleri inceleyerek kişinin karakteri ve yaşamındaki olaylar hakkında ipuçları verir."} />
                <CarouselCard image={handImage} title={"El Falı"} description={"El falı, avuç içi çizgileri ve parmak yapılarından yola çıkarak kişinin yaşam yolu ve geleceğini yorumlar."} />
                <CarouselCard image={numerologyImage} title={"Numeroloji"} description={"Numeroloji, doğum tarihi ve isimlerden elde edilen sayısal değerlerle kişinin yaşam amacını ve potansiyelini analiz eder"} />
                <CarouselCard image={burcImage} title={"Burçlar"} description={"Burç yorumu, zodyak burçlarına göre kişinin karakterini, ilişkilerini ve yaşamındaki önemli olayları yorumlar."} />
                <CarouselCard image={dreamImage} title={"Rüya Yorumu"} description={"Rüya yorumu, rüyalardaki semboller ve olaylar aracılığıyla bilinçaltını analiz eder ve geleceğe dair ipuçları sunar."} />
                <CarouselCard image={astrologyImage} title={"Astroloji"} description={"Astroloji, gezegenlerin ve yıldızların doğum anındaki konumlarına göre kişinin yaşamını ve kişiliğini yorumlar."} />
                <CarouselCard image={luckyNumbersImage} title={"Günlük Şanslı Sayılar"} description={"Tarot falı, 78 karttan oluşan bir desteyle kişinin geçmiş, şimdi ve geleceği hakkında kehanet yapar."} />

            </Slider>

        </div>
    );
}

export default Carousel;
