import React from 'react'
import horizontalLogo from '../assets/images/logo_horizontal.png'
import homeMockup from '../assets/mockup/home.png'
import 'react-multi-carousel/lib/styles.css';
import coffeeImage from '../assets/fortunes/turkish_coffe.png';
import Carousel from '../components/Carousel';
import playStore from '../assets/images/play_store.png'
import appStore from '../assets/images/app_store.png'

const Index = () => {
    return (
        <div className='flex flex-col'>
            <a href="#"><img src={horizontalLogo} className='mx-auto' alt="" style={{ height: 150 }} /></a>
            <div className='h-12 md:h-24' />

            <div className='flex flex-col md:flex-row items-center justify-evenly'>
                <img src={homeMockup} className="w-[200px] md:w-[300px] mb-8 md:mb-0" />
                <div className='flex flex-col w-full px-4 md:px-0 md:w-[450px]'>
                    <h3 className='text-usc-gold text-[36px] md:text-[60px] font-bold text-center md:text-left'>
                        Fallarınız artık cebinizde!
                    </h3>
                    <div className='h-4 md:h-8' />
                    <p className='text-white text-[18px] md:text-[24px] text-center md:text-left'>
                        Yapay zeka ile geleceğinizi keşfedin! Kişisel fal deneyimi sunan uygulamamızla kahve, tarot ve daha fazlasında benzersiz yorumlar alın.
                    </p>
                    <div className='h-6 md:h-10' />
                    <div className='flex flex-row items-center justify-evenly'>
                        <a href="#"><img src={playStore} alt="" className="w-[150px] md:w-auto" /></a>
                        <div className='h-5 md:w-5 md:h-auto' />
                        <a href="#"><img src={appStore} alt="" className="w-[150px] md:w-auto" /></a>
                    </div>
                </div>
            </div>
            <div className='h-24 md:h-48' />
            <div className='bg-[#141414] py-10 px-5'>
                <h3 className='text-[28px] md:text-[40px] font-bold text-white pl-0 md:pl-12 text-center md:text-left'>
                    Her Çeşit Fal Fallasana'da
                </h3>
                <div className='h-8 md:h-16' />
                <Carousel />
            </div>
            <div className='flex flex-col items-center text-white font-semibold space-y-4 md:space-y-6 py-8'>
                <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-20 items-center'>
                    <p className='text-center md:text-left'>Fallasana bir Langugo LLC projesidir</p>
                    <p>Langugo LLC</p>
                    <p className='text-center md:text-left'>İletişim: <a href="mailto:info@fallasana.com" className='text-usc-gold underline decoration-usc-gold'>info@fallasana.com</a></p>
                </div>
                <p className='text-center'>30 North Gould Street, Suite 29711, Sheridan, WY, 82801, USA</p>
                <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-5 items-center'>
                    <a href="/termsOfUse">
                        <p className='text-usc-gold'>Kullanım Şartları</p>
                    </a>
                    <a href="/privacyPolicy">
                        <p className='text-usc-gold'>Gizlilik Politikası</p>
                    </a>
                    <a href="/eula">
                        <p className='text-usc-gold'>EULA</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Index;
