import React from 'react';
import horizontalLogo from '../assets/images/logo_horizontal.png';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className='flex flex-col items-center bg-[#141414] text-white p-5 md:p-10'>
            <a href="/"><img src={horizontalLogo} className='mx-auto' alt="Logo" style={{ height: 100 }} /></a>
            <div className='h-12 md:h-24' />
            <h1 className='text-usc-gold text-[36px] md:text-[48px] font-bold text-center'>
                Gizlilik Politikası
            </h1>
            <div className='h-6 md:h-12' />
            <div className='max-w-4xl text-[18px] md:text-[20px] space-y-6'>
                <p>
                    Bu Gizlilik Politikası, Langugo LLC tarafından geliştirilen ve işletilen "Fallasana" markalı mobil uygulamanın kullanıcılarının gizliliğini korumak ve kişisel verilerin güvenliğini sağlamak amacıyla oluşturulmuştur. Bu politika, Fallasana uygulaması aracılığıyla toplanan verilerin nasıl işlendiğini, kullanıldığını ve korunduğunu ayrıntılı bir şekilde açıklamaktadır. Uygulamayı kullanarak, bu Gizlilik Politikası'nı kabul ettiğinizi ve kişisel verilerinizin bu politikada belirtilen şekilde işleneceğini kabul etmiş sayılırsınız.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>1. Kişisel Bilgilerin Toplanmaması</h2>
                <p>
                    Fallasana uygulaması, kullanıcılarının kimliklerini ve kişisel bilgilerini koruma taahhüdüyle hareket eder. Bu bağlamda, kullanıcılarımızdan herhangi bir e-posta adresi, telefon numarası, sosyal medya hesap bilgisi veya kimlik bilgisi toplamayız. Uygulamanın kullanımı sırasında kullanıcıların herhangi bir kişisel bilgiyi ifşa etmeleri gerekmemektedir, bu sayede kullanıcılar tamamen anonim kalabilirler.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>2. Anonim Demografik Bilgilerin Toplanması</h2>
                <p>
                    Fallasana uygulaması, daha kişiselleştirilmiş bir deneyim sunmak ve kullanıcılarına daha anlamlı fal yorumları sağlamak amacıyla anonim olarak bazı demografik bilgileri talep edebilir. Bu bilgiler arasında isim, doğum tarihi, doğum saati, doğum yeri, eğitim durumu, medeni hal ve maddi durum gibi veriler bulunabilir. Bu demografik bilgiler, kullanıcı kimlikleriyle ilişkilendirilmeden ve tamamen anonim olarak toplanmakta ve işlenmektedir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>3. Otomatik Bilgi Toplama ve Kullanımı</h2>
                <p>
                    Fallasana, uygulamanın performansını iyileştirmek, kullanıcı deneyimini geliştirmek ve uygulamanın genel işleyişini analiz etmek amacıyla bazı anonim kullanım verilerini otomatik olarak toplayabilir. Bu veriler arasında uygulama içi kullanım süreleri, uygulamada en sık ziyaret edilen bölümler ve cihaz türü gibi teknik bilgiler yer alabilir. Bu bilgiler, kullanıcının kimliği ile ilişkilendirilmez ve yalnızca genel kullanıcı eğilimlerini analiz etmek amacıyla kullanılır.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>4. Üçüncü Taraf Hizmet Sağlayıcılar</h2>
                <p>
                    Fallasana uygulaması, kullanıcılara daha iyi hizmet sunabilmek amacıyla üçüncü taraf hizmet sağlayıcılarıyla işbirliği yapabilir. Bu üçüncü taraflar, uygulama içi reklamlar, ödeme işlemleri ve analiz hizmetleri gibi alanlarda faaliyet gösterebilir. Ancak, bu üçüncü tarafların kullanıcıların kişisel bilgilerine erişimi bulunmamaktadır ve yalnızca anonim olarak toplanan veriler üzerinde işlem yapabilirler.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>5. Reklamlar ve Ücretsiz Elmas Kazanma</h2>
                <p>
                    Fallasana uygulaması, kullanıcılarına ücretsiz elmas kazanma fırsatı sunan reklamlar içerebilir. Bu reklamlar, üçüncü taraflarca sağlanır ve kullanıcıların izni ile izlenebilir. Reklam izleme işlemi sırasında herhangi bir kişisel bilgi toplanmaz ve üçüncü taraflara aktarılmaz. Kullanıcıların izleme faaliyetleri anonim olarak kaydedilir ve yalnızca ilgili ücretsiz elmas ödüllerini sağlamak amacıyla kullanılır.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>6. Veri Güvenliği</h2>
                <p>
                    Fallasana, kullanıcı bilgilerinin güvenliğini sağlamak için endüstri standartlarına uygun güvenlik önlemleri ve protokolleri uygular. Anonim olarak toplanan tüm veriler, yetkisiz erişim, değişiklik, ifşa veya imha risklerine karşı korunmaktadır. Fallasana, veri güvenliğini sağlamak amacıyla düzenli olarak güvenlik değerlendirmeleri yapar ve güvenlik protokollerini günceller.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>7. Çerezler (Cookies) ve Benzeri Teknolojiler</h2>
                <p>
                    Fallasana, kullanıcı deneyimini iyileştirmek ve uygulamanın performansını artırmak amacıyla çerezler ve benzeri teknolojiler kullanabilir. Çerezler, kullanıcıların tarayıcılarına kaydedilen küçük veri dosyalarıdır ve kullanıcıların uygulama içinde daha iyi bir deneyim yaşamasını sağlar. Kullanıcılar, tarayıcı ayarlarını değiştirerek çerez kullanımını reddetme hakkına sahiptir; ancak bu durumda, uygulamanın bazı özelliklerine erişim kısıtlanabilir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>8. Gizlilik Politikası'nda Değişiklikler</h2>
                <p>
                    Fallasana, bu Gizlilik Politikası'nı dilediği zaman güncelleme veya değiştirme hakkını saklı tutar. Yapılan değişiklikler, kullanıcıların dikkatine sunulacak ve güncellenen politika uygulama üzerinden erişilebilir hale getirilecektir. Bu Gizlilik Politikası'nda yapılan değişikliklerin ardından uygulamayı kullanmaya devam etmeniz, güncellenmiş politikayı kabul ettiğiniz anlamına gelecektir.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>9. Kullanıcı Hakları</h2>
                <p>
                    Kullanıcılar, anonim olarak toplanan bilgilerinin işlenmesi ile ilgili olarak belirli haklara sahiptir. Bu haklar arasında bilgi talep etme, verilerin düzeltilmesi veya silinmesi talepleri bulunabilir. Kullanıcılar, bu haklarını kullanmak istediklerinde Fallasana destek ekibiyle iletişime geçebilirler.
                </p>

                <h2 className='text-usc-gold text-[24px] md:text-[32px] font-semibold'>10. Yargı Yetkisi</h2>
                <p>
                    Bu Gizlilik Politikası, Amerika Birleşik Devletleri, Wyoming Eyaleti yasalarına tabi olup, bu yasalara göre yorumlanacak ve uygulanacaktır. Fallasana uygulamasıyla ilgili herhangi bir ihtilaf durumunda, yalnızca Wyoming Eyaleti mahkemeleri yetkili olacaktır.
                </p>

                <p>
                    Fallasana uygulamasını kullanarak, yukarıda belirtilen Gizlilik Politikası'nı dikkatlice okuduğunuzu, anladığınızı ve kabul ettiğinizi beyan etmiş olursunuz. Gizliliğinizin korunması konusunda herhangi bir sorunuz veya endişeniz olması durumunda, aşağıda belirtilen destek e-posta adresi üzerinden bizimle iletişime geçebilirsiniz.
                </p>
                <p>
                    Her türlü soru ve önerileriniz için e-posta adresi: <a href="mailto:info@fallasana.com" className='text-usc-gold underline'>info@fallasana.com</a>
                </p>
                <p>Son Güncelleme: 01.09.2024</p>
            </div>
            <div className='h-8 md:h-16' />
        </div>
    );
}

export default PrivacyPolicy;
